/* Reemplazar las url locales por las de producción */

.parrafo-ourjourney{
    font-size: 27px;
  }
.master-the-moment{
    background-image: linear-gradient(180deg, rgba(76, 193, 187, 1) 5%, transparent 40%), url(https://tequilapuertobello.tuwebinteligente.com/images/Enmascarar%20grupo%2013.png);
    position: relative;
    height: auto;
    min-height: 30vw;
    width: 100%;
    padding: 150px 0;
    /* background-position: center top, bottom; */
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: cover;
}
.bgTextura_azul{
    background-image: url('https://tequilapuertobello.tuwebinteligente.com/images/bg_home1.png');
    background-position: right bottom;
    background-repeat: no-repeat;
}
.bgTextura_verde{
    background-image: url('https://tequilapuertobello.tuwebinteligente.com/images/bg_home1.png');
    background-position: right bottom;
    background-repeat: no-repeat;
}
.bgTextura_verde_textura-transparente{
    background-image: linear-gradient(180deg, #2d9870 5%, #2d987099 40%), url('https://tequilapuertobello.tuwebinteligente.com/images/bg_home1.png');
    background-position: right bottom;
    background-repeat: no-repeat;

}
.animationCoktail{
    transition: all ease 0.5s;
}

.textBottomBottle{
    width: 100%;
    /* position: relative; */
    left: 0;
    font-size: 30px;
    margin-top: 70px;
}

.tacheAwards{
    top:75vh;
    left:8vw;
    width:38px;
}


.sliderOurTequila{
    transition: all ease 0.5s;
}
.imageOurTequila{
    transition: all ease 0.5s;
    width: 580px;
}

.tituloPackage{
    padding-left: 0;
    padding-right: 8vw;
}
.ourTequilaHeader{
    padding-bottom: 250px !important;
}

.meetOurTequilaPackageSection{
    padding:0 6vh;
}

.sectionPackageMeetOurTequila{
    margin-bottom: 50px;
}

.seccionInteractiva{
    display: block !important;
}
.seccionProductos{
    display: none !important;
}
.seccionHeaderMeetTequila{
    display: flex;
    flex-direction: row;
    width: 100%;
    padding-left: 200px;
    padding-top: 80px;
    padding-bottom: 50px;
}


.seccionHeaderMeetTequila .meetOurTequilaTitulo{
    margin-bottom: 50px;
}

.seccionHeaderMeetTequila .meetOurTequilaDescripcion{
    margin-bottom: 70px;
}
.tequilaInYourInbox{
    line-height: 100px !important;
}

.textoFooter{
    font-size: 16px !important;
    letter-spacing: 2px;
    text-align: left;
}

.our-tequila {
    height: 80vh !important;
    padding-top: 300px !important;
}

.seccionPackageMovil{
    display: none !important;
}
.seccionPackageDesktop{
    display: flex !important;
    padding-top: 100px;
}
.seccionPackageSharing{
    display:block;
    padding-top: 100px;
}

.parrafoPackage{
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}
.tituloPackage {
    padding-left: 0 !important;
    padding-right: 0vw !important;
    display: flex !important;
    justify-content: center !important;
}

.sectionHowMade {
    align-items: flex-start !important;
    padding-top: 150px !important;
}
.text-cocktail-bisque {
    --tw-text-opacity: 1;
    color: #4cc1bb !important;
}
.text-cocktail-green {
    --tw-text-opacity: 1;
    color: #c25b55 !important;
}

.paralaxSection{
    width: 100%;
    display: flex;
    height: 40vh;
    background-image: url(https://tequilapuertobello.com/images/bg_paralax.jpg);
    background-attachment: fixed;
    background-size: cover;
}
.paralaxSectionOurTequila{
    width: 100%;
    display: flex;
    height: 40vh;
    background-image: url(https://tequilapuertobello.com/images/arena_parallax.png);
    background-attachment: fixed;
    background-size: cover;
}
.bg-our-tequila {
    background-image: linear-gradient(180deg, #EBBD78 5%, transparent 40%), url(https://tequilapuertobello.com/images/Enmascarar%20grupo%2015.png) !important;
}

@media(max-width: 2200px){
    .botellaAwards{
        width:50% !important;
        margin-top:-600px;
    }
    .tacheAwards{
        top:105vh;
        left:15vw;
        width:50px;
    }
    .textBottomBottle{
        width: 100%;
        /* position: relative; */
        left: 0;
        font-size: 30px;
        margin-top: -90px;
    }

    .tituloOurTequila{
        margin-right: 10rem !important;
        margin-bottom: 3rem !important;
        font-size: 50px !important;
    }
    .descripcionOurTequila{
        margin-bottom: 0px;
        min-height: 91px;
        font-size: 30px !important;
    }
    .botonMeetMeOurTequila{
        font-size: 23px !important;
        padding: 10px 35px !important;
    }

    .tituloCocktail{
        font-size: 75px !important;
    }
    @keyframes AnimImgTequila{
        from{
            opacity: 0;
        }
        to{
            opacity: 1;
        }
    }
    .imageOurTequila{
        animation-name: AnimImgTequila;
        animation-fill-mode: forwards;
        animation-timing-function: ease;
        animation-duration: 0.5s;
    }
    .ourTequilaHeader{
        padding-bottom: 250px !important;
    }

    
}

@media(max-width: 1366px){
    .texto-parrafos{
        font-size: 16px !important;
    }

    .tacheAwards{
        top:75vh;
        left:8vw;
        width:38px;
    }


    .tituloPackage{
        font-size: 27px !important;
    }
    .parrafoPackage{
        font-size: 24px !important;
    }
    .tituloOurTequila{
        margin-right: 5rem !important;
        margin-bottom: 3rem !important;
        font-size: 40px !important;
    }
    .descripcionOurTequila{
        margin-bottom: 0px;
        min-height: 91px;
    }

    
    .botonMeetMeOurTequila{
        font-size: 20px !important;
        padding: 8px 30px !important;
    }
    
    .xl\:py-\[250px\] {
        padding-top: 0px;
        padding-bottom: 250px;
    }
    .tituloCocktail{
        font-size: 60px !important;
    }
    .ourTequilaHeader{
        padding-bottom: 250px !important;
    }
    .tituloPackage{
        font-size: 24px !important;
        text-align: center !important;
        display: flex;
        justify-content: center;
        padding-left: 0;
        padding-right: 0vw;
    }
    .seccionHeaderMeetTequila{
        display: flex;
        flex-direction: row;
        width: 100%;
        padding-left: 100px;
        padding-top: 50px;
        padding-bottom: 50px;
    }
    
    .seccionHeaderMeetTequila .meetOurTequilaTitulo{
        margin-bottom: 30px;
    }
    
    .seccionHeaderMeetTequila .meetOurTequilaDescripcion{
        margin-bottom: 50px;
    }

    .sectionHowMade {
        align-items: flex-start !important;
        padding-top: 80px !important;
    }

    .sectionHowMade p{
        font-size: 16px;
        margin-bottom: 30px !important;
    }
    .sectionHowMade h3{
        font-size: 20px;
        margin-bottom: 0px !important;
    }
    .sectionHowMade h2{
        font-size: 30px;
    }
    .sectionHowMade .space-y-4, .sectionHowMade .space-y-2{
        margin-top: 0px !important;
        margin-bottom: 0px !important;
    }

    .meetOurTequilaTitulo{
        font-size: 30px !important;
        line-height: 30px;
        letter-spacing: 3px;
    }
    .meetOurTequilaDescripcion{
        font-size: 16px !important;
        margin-right: 70px;
        margin-bottom: 0;
    }
    .buttonBuyMeMeetTequila{
        margin-top: 25px !important;
        margin-right: 15px;
        font-size: 18px !important;
    }

    .tituloPackage{
        font-size: 24px !important;
        text-align: center !important;
        display: flex;
        justify-content: center;
        padding-left: 0;
        padding-right: 0vw;
    }
    .parrafoPackage{
        font-size: 16px !important;
    }
    .meetOurTequilaPackageSection{
        padding-bottom: 100px !important;
    }
}

@media(max-width:1024px){
    .sectionHowMade p{
        font-size: 16px;
        margin-bottom: 10px !important;
    }
    .master-the-moment{
        background-image: linear-gradient(180deg, rgba(76, 193, 187, 1) 5%, transparent 40%), url(https://tequilapuertobello.tuwebinteligente.com/images/Enmascarar%20grupo%2013.png) !important;
        position: relative !important;
        height: auto !important;
        min-height: 50vw !important;
        width: 100% !important;
        padding: 120px 0 !important;
        /* background-position: center top, bottom !important; */
        background-repeat: no-repeat !important;
        background-position: center bottom !important;
        background-size: cover !important;
    }

    .text-hero {
        font-size: 60px !important;
        line-height: 80px !important;
    }

    .our-tequila {
        height: 50vh !important;
        padding-top: 100px !important;
    }

    .tituloOurTequila {
        margin-right: 0rem !important;
    }

    .privacy-police {
        position: relative;
        height: 40vh !important;
        min-height: 40vw !important;
        padding: 100px 0 !important;
        background-size: cover !important;
        background-position: center top, bottom;
        background-repeat: no-repeat;
    }

    .tituloContactUs{
        font-size: 40px !important;
        margin-bottom: 0px !important;
        margin-top: 0px !important;
    }
    .buttonSendContactUs{
        font-size: 20px !important;
        padding-top: 0rem !important;
        padding-bottom: 0rem !important;
    }
    .inputSection{
        font-size: 20px !important;
        line-height: 20px !important;
        margin-top: 2rem !important;
        margin-bottom: 2rem !important;
    }

    .inputSection input{
        border-bottom: 1px solid #FFFFFF !important;
    }


    .lg\:text-sm {
        font-size: 14px !important;
        line-height: 26px !important;
    }

    .tacheAwards{
        top:75vh !important;
        left:10vw !important;
        width:38px !important;
    }
    .botellaAwards{
        width:70% !important;
        margin-top:-400px;
    }
    .textBottomBottle{
        width: 100%;
        /* position: relative; */
        left: 0;
        font-size: 30px;
        margin-top: -140px;
    }
    .cocktail-header {
        padding: 0 !important;
    }
    .tituloCocktail{
        font-size: 50px !important;
    }
    .ourTequilaHeader{
        padding-bottom: 0px;
    }
    .meetOurTequilaTitulo{
        font-size: 40px !important;
        line-height: 30px;
        letter-spacing: 3px;
    }
    .meetOurTequilaDescripcion{
        font-size: 18px !important;
        margin-right: 70px;
        margin-bottom: 0;
    }
    .buttonBuyMeMeetTequila{
        margin-top: 30px !important;
        margin-right: 20px;
        font-size: 20px !important;
    }
    .sectionHowMade {
        align-items: flex-start !important;
        padding-top: 150px !important;
    }
    .sectionHowMade p{
        font-size: 16px;
    }
    .sectionHowMade h3{
        font-size: 24px;
    }
    .sectionHowMade h2{
        font-size: 40px;
    }
    .sectionHowMade .space-y-4, .sectionHowMade .space-y-2{
        margin-top: 20px !important;
    }
    .tituloPackage{
        font-size: 24px !important;
        text-align: center !important;
        display: flex;
        justify-content: center;
        padding-left: 0;
        padding-right: 0vw;
    }
    .parrafoPackage{
        font-size: 16px !important;
    }
    .ourTequilaHeader {
        padding-bottom: 100px !important;
    }

    .master-the-moment .trazado-superior-derecha {
        top: 150px !important;
        right: 195px !important;
        width: 40px !important;
        height: 56px !important;
    }
    .master-the-moment .trazado-inferior-izquierda {
        top: 50px !important;
        left: 40px !important;
        width: 20px !important;
        height: 56px !important;
        display: none !important;
    }
    .master-the-moment .trazado-inferior-derecha {
        bottom: 50px !important;
        left: 20px !important;
        width: 35px !important;
        height: 34px !important;
    }
}

@media(max-width: 768px){
    .texto-parrafos{
        font-size: 16px !important;
    }
    .-mt-20 {
        margin-top: 0;
    }

    .botellaAwards{
        width:60% !important;
        margin-top:-350px;
    }
    .textBottomBottle{
        width: 100%;
        /* position: relative; */
        left: 0;
        font-size: 23px;
        margin-top: 0px;
    }

    .md\:bg-awards-rotate {
        background-image: url(https://tequilapuertobello.tuwebinteligente.com/images/Mask%20Group%2020@2x.png) !important;
    }

    .tacheAwards{
        top:40vh !important;
        left:10vw !important;
        width:25px !important;
    }

    .craft-puerto-bello {
        padding: 100px 0 !important;
    }
    .tequilaInYourInbox{
        line-height: 40px !important;
        letter-spacing: 5px !important;
        font-size: 26px !important;
    }

    .tituloOurTequila{
        margin-right: 0rem !important;
        margin-bottom: 1rem !important;
        font-size: 40px !important;
        line-height: 40px !important;
    }
    .descripcionOurTequila{
        margin-bottom: 0px;
        min-height: 50px;
    }
    .buttonBuyMeMeetTequila{
        margin-top: 15px !important;
        margin-right: 10px !important;
        font-size: 20px !important;
    }
    .px-32 {
        padding-left: 3rem !important;
        padding-right: 3rem !important;
    }
    .ourTequilaHeader {
        padding-bottom: 50px !important;
    }
    .h-5\/6 {
        height: 50vh !important;
    }
    .w-3\/4 {
        width: 90% !important;
    }
    .meetOurTequilaTitulo {
        width: 100%;
        padding:0px 25px ;
        margin: 30px 0px 0px 0px;
        font-size: 25px !important;
        line-height: 33px !important;
    }
    .meetOurTequilaDescripcion{
        width: 100%;
        margin: 0;
        margin-bottom: 70px;
        margin-top: 30px;
        font-size: 14px !important;
        line-height: 20px !important;
        padding:0px 0px 0px 25px;
    }

    .buttonBuyMeMeetTequila{
        margin-top: 0px !important;
        margin-right: 0px;
        margin-left: 25px;
        font-size: 16px !important;
    }

    .h-screen {
        height: 100vh !important;
    }
    .h-full{
        height:100vh !important;
    }
    .sectionHowMade{
        padding-top: 50px;
        height: 50vh;
    }
    .sectionHowMade p{
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 35px !important;
    }
    .sectionHowMade h3{
        font-size: 18px;
        line-height: 20px;
    }
    .sectionHowMade h2{
        font-size: 25px;
    }
    .sectionHowMade .space-y-4, .sectionHowMade .space-y-2{
        margin-top: 15px !important;
    }
    .px-6 {
        padding-left: 0;
        padding-right: 0;
    }

    .meetOurTequilaPackageSection{
        padding:0 0vh;
        padding-top: 0px;
    }
    .tituloPackage{
        font-size: 16px !important;
        text-align: left !important;
        display: flex;
        justify-content: center;
        padding-left: 0;
        padding-right: 0vw;
    }
    .tituloPackage h3{
        font-size: 30px;
    }
    .parrafoPackage{
        font-size: 14px !important;
    }
    .sectionPackageMeetOurTequila{
        margin-bottom: 20px !important;
    }
    .mb-10 {
        margin-bottom: 0;
    }
    
    .mt-16 {
        margin-top:0;
    }

    .tituloSharing{
        font-size: 40px !important;
    }

    .tituloCocktail{
        font-size: 30px !important;
    }
    .radial-gradient-green {
        background: transparent linear-gradient(0deg, #4CC1BA 10%, #4CC1BB00 25%) 0% 0% no-repeat padding-box !important;
        opacity: 1;
        display: block !important;
    }
    .radial-gradient-blue {
        background: transparent linear-gradient(0deg, #147AD8 10%, #4CC1BB00 20%) 0% 0% no-repeat padding-box !important;
        opacity: 1;
        display: block !important;
    }
    .tituloProcess{
        font-size: 35px !important;
    }

    .radial-gradient-white {
        background: transparent linear-gradient(0deg, #f8f6ef 20%, #f8f6ef00 30%) 0% 0% no-repeat padding-box;
        opacity: 0.5;
    }
    .ourTequilaHeader {
        padding-bottom: 100px !important;
    }
    .li_elementCocktail li {
        margin: 10px 15px;
        width: 50px;
        font-weight: 400 !important;
        font-size: 16px !important;
        line-height: 24px;
    }

    .tituloContactUs{
        font-size: 50px !important;
    }

    .radial-gradient-white {
        background: transparent linear-gradient(0deg, #f8f6ef 8%, #f8f6ef00 15%) 0% 0% no-repeat padding-box !important;
        opacity: 1 !important;
    }

    .privacy-police {
        position: relative;
        height: auto;
        min-height: 40vw;
        padding: 20px 0;
        background-size: 100% auto, contain;
        background-position: center top, bottom;
        background-repeat: no-repeat;
    }
    .our-tequila {
        height: 30vh !important;
    }
    .paralaxSection{
        height: 30vh !important;
    }
    .meetOurTequilaTitulo {
        width: 100%;
        padding: 0px 20    px;
        margin: 20px 0px 0px 0px;
        font-size: 22px !important;
        line-height: 33px !important;
    }
    .bg-mask-soft-blue {
        background-image: none !important                                                                          
    }
    .sectionHowMade .space-y-4, .sectionHowMade .space-y-2 {
        margin-top: 0px !important;
    }
    .parrafoContactUs{
        padding-left: 0rem !important;
        font-size: 12px !important;
    }

    .height70{
        height: 70vh !important;
    }
    .height70 .h-full{
        height: 70vh !important;
    }
    .height90{
        height: 90vh !important;
    }
    .height80{
        height: 70vh !important;
    }
    .padding0{
        padding-top: 0px !important;
    }
    .padding50 .sectionHowMade{
        padding-top:150px !important
    }
    .imagenHowMade img{
        width: 140% !important;
        max-width: 140% !important;
    }
    .seccionPackageSharing{
        padding-top: 0px !important;
    }
    .sectionHowMade{
        overflow-x: hidden !important;
    }
}


@media(max-width: 460px){
    .imagenHowMade img{
        width: 100% !important;
        max-width: 100% !important;
    }
    .master-the-moment .trazado-superior-derecha {
        top: 230px !important;
        right: 75px !important;
        width: 30px !important;
        height: 56px !important;
    }
    .master-the-moment .trazado-inferior-izquierda {
        top: 50px !important;
        left: 40px !important;
        width: 20px !important;
        height: 56px !important;
    }
    .master-the-moment .trazado-inferior-derecha {
        bottom: 100px !important;
        left: 50px !important;
        width: 29px !important;
        height: 33px !important;
    }

    .florMasterTheMoment{
        
    }
    .textoMasterTheMoment{
        font-size: 30px;
    }
    .master-the-moment {
        background-image: linear-gradient(to bottom, #4CC1BBAA, #4CC1BBAA) , url(https://tequilapuertobello.com/images/aro_blanco.png) !important;
        position: relative;
        height: auto;
        min-height: 100vh !important;
        width: 100%;
        padding: 0px 0 !important;
        /* background-position: center top, bottom; */
        background-repeat: no-repeat !important;
        background-position: center center !important;
        background-size: auto 130vw !important;
        
    }
    .bgTextura_verde{
        background-image: none;
    }
    .xs\:w-\[89px\] {
        width: 60px !important;
    }

    .h-screen {
        height: 100vh !important;
    }
    .h-full{
        height:100vh !important;
    }

    .botellaAwards{
        width:150% !important;
        margin-top:200px;
    }
    .textBottomBottle{
        width: 100%;
        /* position: relative; */
        left: 0;
        font-size: 20px;
        margin-top: -50px;
    }
    .tacheAwards{
        top: 90vh !important;
        left: 20vw !important;
        width: 25px !important;
    }
    .tequilaInYourInbox{
        line-height: 30px !important;
        letter-spacing: 5px !important;
        font-size: 18px !important;
        margin-bottom: 20px;
    }

    .radial-gradient-green {
        background: transparent linear-gradient(0deg, #4CC1BA00 10%, #4CC1BB00 40%) 0% 0% no-repeat padding-box !important;
        opacity: 1;
        display: none !important;
    }
    .radial-gradient-blue {
        background: transparent linear-gradient(180deg, transparent, #147AD944 100%, #147AD900) 0% 0% no-repeat padding-box !important;
        opacity: 1;
        display: none !important;
    }
    .tituloProcess{
        font-size: 35px !important;
    }

    .radial-gradient-white {
        background: transparent linear-gradient(0deg, #f8f6ef00 20%, #f8f6ef00 30%) 0% 0% no-repeat padding-box !important;
        opacity: 0;
    }

    .seccionInteractiva{
        display: none !important;
    }
    .seccionProductos{
        display: block !important;
        padding-top: 100px;
    }

    .tituloOurTequila{
        margin-right: 0rem !important;
        margin-bottom: 1rem !important;
        font-size: 20px !important;
        line-height: 40px !important;
        text-align: left !important;
    }
    .descripcionOurTequila{
        margin-bottom: 0px;
        min-height: 30px;
        text-align: left !important;
    }
    .seccionProductos .producto{
        margin-top: 30px !important;
        margin-bottom: 30px !important;
        padding-top: 120px !important;
    }

    .botonMeetMeOurTequila {
        width: 100%;
        text-align: center;
    }

    .seccionHeaderMeetTequila{
        display: flex;
        flex-direction: row;
        width: 100%;
        padding-left: 20px;
    }

    .seccionHeaderMeetTequila{
        display: flex;
        flex-direction: column-reverse;
        width: 100%;
        padding-left: 0px;
        padding-top: 30px;
        padding-bottom: 50px;
    }

    .seccionHeaderMeetTequila > div{
        width: 100% !important;

    }
    
    .seccionHeaderMeetTequila .meetOurTequilaTitulo{
        margin-bottom: 30px;
        text-align: left;
        font-size: 18px !important;
        line-height: 24px;
    }
    
    .seccionHeaderMeetTequila .meetOurTequilaDescripcion{
        margin-bottom: 50px;
        text-align: left;
        line-height: 18px;
    }

    .seccionHeaderMeetTequila .buttonBuyMeMeetTequila{
        width: 100% !important;
        text-align: center !important;
    }

    .sectionHowMade{
        display: flex !important;
        flex-direction: column !important;
    }

    .sectionHowMade .space-y-2{
        display: flex !important;
        flex-direction: row !important;
        align-items: center !important;
    }
    .sectionHowMade .space-y-2 h3{
        margin-right: 40px;
        font-size: 20px;
        width: 30%;
    }
    .sectionHowMade .space-y-2 p{
        margin: 0 !important;
        margin-top: 5px !important;
        margin-bottom: 5px !important;
    }

    .sectionHowMade .imagenHowMade{
        margin-top: 70px;
    }

    .sectionPackageMeetOurTequila {
        margin-bottom: 0px !important;
        width: 100% !important;
        flex-direction: column !important;
        margin-top: 0px;
    }

    .sectionPackageMeetOurTequila > div{
        width: 100% !important;
    }
    .parrafoPackage{
        text-align: center !important;
    }
    .sectionPackageMeetOurTequila .tituloPackage{
        margin: 0 !important;
        width:100%;
        justify-content: flex-start;
    }
    .tituloProcess {
        font-size: 20px !important;
        line-height: 30px !important;
    }
    .texto-parrafos{
        font-size: 14px !important;
    }
    .tituloContactUs{
        font-size: 30px !important;
        margin-bottom: 0px !important;
        margin-top: 0px !important;
    }
    .pl-44 {
        padding-left: 0rem !important;
    }
    .buttonSendContactUs{
        font-size: 18px !important;
        padding-top: 0rem !important;
        padding-bottom: 0rem !important;
    }
    .inputSection{
        font-size: 16px !important;
        line-height: 20px !important;
        margin-top: 2rem !important;
        margin-bottom: 2rem !important;
    }
    .inputSection input{
        border-bottom: 1px solid #FFFFFF !important;
    }

    .seccionPackageMovil{
        display: flex !important;
        padding-top: 100px !important;
    }
    .seccionPackageDesktop{
        display: none !important;
    }
    .sliderTragos{
        display: none !important;
    }
    hr{
        display: none !important;
    }

    .contenedorBotonesGaleria{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        color: #FFFFFF;
    }
    .contenedorBotonesGaleria button{
        font-size: 14px !important;
    }
    .imagenGaleria{
        width: 100%;
        display: flex;
    }
    .imagenGaleria img{
        width: 100%;
        display: flex;
        margin-right: 10vw;
    }
    .bg-craft-puerto-bello {
        background-image: url(https://tequilapuertobello.com/images/aro_rosa.png) !important;
        background-position: center center !important;
        height: 70vh !important;
    }
    .our-tequila {
        height: 80vh !important;
    }
    .bg-our-tequila {
        background-image: url(https://tequilapuertobello.com/images/aro_amarillo.png) !important;
        background-size: 110% auto !important;
        background-position: center center !important;
    }
    .cocktail-header {
        position: relative;
        height: 70vh !important;
        min-height: 40vw;
        padding: 150px 0;
        background-size: 120% auto, contain !important;
        background-position: center center !important;
        background-repeat: no-repeat;
        
    }
    .bg-cocktail-header {
        background-image: url(https://tequilapuertobello.com/images/aro_azul.png) !important;
    }
    .bg-cocktail-blue > img:first-child{
        opacity: 0 !important;
    }
    .p-48 {
        padding: 15px !important;
    }
    .p-48 p {
        font-size: 12px !important;
        line-height: 20px;
    }
    .privacy-police {
        position: relative;
        height: 70vh !important;
        min-height: 40vh !important;
        padding: 150px 0;
        background-size: 100% auto, contain;
        background-position: center top, bottom;
        background-repeat: no-repeat;
    }
    .text-hero {
        font-size: 40px !important;
        line-height: 60px !important;
    }
    .aro_process img{
        opacity: 0 !important;
    }
    .seccionPackageSharing{
        display: none;
    }
    .buttonSendContactUs{
        width: 100% !important;
    }

    .height70{
        height: 70vh !important;
    }
    .height90{
        height: 90vh !important;
    }
    .height80{
        height: 80vh !important;
    }
    .padding0{
        padding-top: 0px !important;
    }
    .padding50 .sectionHowMade{
        padding-top:50px !important
    }
    .botonMeetMeOurTequila{
        margin-top: 30px;
    }
    .descripcionOurTequila{
        font-size: 21px !important;
    }

    .seccionProductos .producto{
        margin-top: 0px !important;
        margin-bottom: 0px !important;
    }
    .seccionProductos .producto:first-child{
        margin-top: 0px !important;
    }
    .tituloheaderMovil{
        margin-top: 10px !important;
    }
}