@import url('https://use.typekit.net/tki3wxf.css');

@tailwind base;
@layer base {
  @font-face {
    font-family: MainSail;
    font-weight: 400;
    src: url(./fonts/MainSail/MainsailCPC-Calm.otf) ;
  }
  @font-face {
    font-family: MainSail;
    font-weight: 500;
    src: url(./fonts/MainSail/MainsailCPC-Windy.otf) ;
  }
  @font-face {
    font-family: MainSailSlab;
    font-weight: 400;
    src: url(./fonts/MainSail/MainsailSlabCPC-Calm.otf);
  }
  @font-face {
    font-family: MainSailSlab;
    font-weight: 500;
    src: url(./fonts/MainSail/MainsailSlabCPC-Windy.otf) ;
  }
  @font-face {
    font-family: Quicksand;
    font-weight: 400;
    src: url(./fonts/Quicksand/Quicksand-Regular.ttf) ;
  }
  @font-face {
    font-family: Quicksand;
    font-weight: 500;
    src: url(./fonts/Quicksand/Quicksand-Medium.ttf) ;
  }
  @font-face {
    font-family: Quicksand;
    font-weight: 600;
    src: url(./fonts/Quicksand/Quicksand-SemiBold.ttf) ;
  }
  @font-face {
    font-family: Quicksand;
    font-weight: 300;
    src: url(./fonts/Quicksand/Quicksand-Light.ttf) ;
  }
  @font-face {
    font-family: Quicksand;
    font-weight: 700;
    src: url(./fonts/Quicksand/Quicksand-Bold.ttf) ;
  }
  @font-face {
    font-family: Turquoise;
    src: url(./fonts/Turquoise/Turquoise.ttf) ;
  }
  @font-face {
    font-family: Athelas;
  font-weight: 700;
    src: url(./fonts/Athelas/Athelas-Bold.woff) ;
  }
  @font-face {
    font-family: Athelas;
  font-weight: 400;
    src: url(./fonts/Athelas/Athelas-Regular.woff) ;
  }
}
@tailwind utilities;
@font-face {
  font-family: MainSail;
  font-weight: 400;
  src: url(./fonts/MainSail/MainsailCPC-Calm.otf) ;
}
@font-face {
  font-family: MainSail;
  font-weight: 500;
  src: url(./fonts/MainSail/MainsailCPC-Windy.otf) ;
}
@font-face {
  font-family: MainSailSlab;
  font-weight: 400;
  src: url(./fonts/MainSail/MainsailSlabCPC-Calm.otf) ;
}
@font-face {
  font-family: MainSailSlab;
  font-weight: 500;
  src: url(./fonts/MainSail/MainsailSlabCPC-Windy.otf) ;
}
@font-face {
  font-family: Quicksand;
  font-weight: 400;
  src: url(./fonts/Quicksand/Quicksand-Regular.ttf) ;
}
@font-face {
  font-family: Quicksand;
  font-weight: 500;
  src: url(./fonts/Quicksand/Quicksand-Medium.ttf) ;
}
@font-face {
  font-family: Quicksand;
  font-weight: 600;
  src: url(./fonts/Quicksand/Quicksand-SemiBold.ttf) ;
}
@font-face {
  font-family: Quicksand;
  font-weight: 300;
  src: url(./fonts/Quicksand/Quicksand-Light.ttf) ;
}
@font-face {
  font-family: Quicksand;
  font-weight: 700;
  src: url(./fonts/Quicksand/Quicksand-Bold.ttf) ;
}
@font-face {
  font-family: Turquoise;
  font-weight: 400;
  src: url(./fonts/Turquoise/Turquoise.ttf) ;
}
@font-face {
  font-family: Athelas;
  font-weight: 700;
  src: url(./fonts/Athelas/Athelas-Bold.woff) ;
}
@font-face {
  font-family: Athelas;
  font-weight: 400;
  src: url(./fonts/Athelas/Athelas-Regular.woff) ;
}
.quicksand {
  font-family: Quicksand, monospace, arial;
}

.mainsail-calm {
  font-family: MainSailSlab, monospace;
}

.mainsail {
  font-family: MainSail, monospace;
}

.turqoise {
  font-family: Turquoise, monospace;
}

.athelas {
  font-family: Athelas, monospace;
}

.radial-gradient-white {
  background: transparent linear-gradient(0deg, #f8f6ef 50%, #f8f6ef00 75%) 0% 0% no-repeat padding-box;
  opacity: 1;
}
.radial-gradient-green {
background: transparent linear-gradient(0deg, #4CC1BB 50%, #4CC1BB00 100%) 0% 0% no-repeat padding-box;
  opacity: 1;
}
.radial-gradient-blue {
  background: transparent linear-gradient(180deg, transparent, #147AD9 70%, #147AD9) 0% 0% no-repeat padding-box;
  opacity: 1;
}

.master-the-moment {
  background-position: center top, bottom;
  background-repeat: no-repeat;
}

.craft-puerto-bello {
  position: relative;
  height: auto;
  min-height: 40vw;
  width: 100%;
  padding: 150px 0;
  background-size: 100% auto, contain;
  background-position: center top, bottom;
  background-repeat: no-repeat;
}


.our-tequila {
  position: relative;
  height: auto;
  width: 100%;
  background-size: 100% auto;
  background-position: center top, bottom;
  background-repeat: no-repeat;
}

@media only screen and (max-width: 640px) {
	.our-tequila {
		background-size: auto 75%;
	}
}

.privacy-police {
  position: relative;
  height: auto;
  min-height: 40vw;
  padding: 150px 0;
  background-size: 100% auto, contain;
  background-position: center top, bottom;
  background-repeat: no-repeat;
}

.cocktail-header {
  position: relative;
  height: auto;
  min-height: 40vw;
  padding: 150px 0;
  background-size: 100% auto, contain;
  background-position: center top, bottom;
  background-repeat: no-repeat;
}

.master-the-moment .trazado-superior-derecha {
  top: 299px;
  right: 24%;
  width: 70px;
  height: 56px;
}

.master-the-moment .trazado-inferior-izquierda {
  bottom: 100px;
  left: 100px;
  width: 70px;
  height: 56px;
}

.master-the-moment .trazado-inferior-derecha {
  bottom: 13%;
  right: 10%;
  width: 39px;
  height: 33px;
}

.awards {
  background-color: #147ad9;
  height: auto;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.small-cactus {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
}
.small-section {
  height: 317px;
}

.our-journey {
  background-color: #2d9870;
  background-size: 1216px 1216px;
  background-position: bottom right;
  background-repeat: no-repeat;
}

.footer {
  background-color: #231f20;
  width: 100%;
  position: relative;
  float: left;
}

.parallax { 
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}